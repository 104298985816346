
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    placeholder: String,
  },
  emits: ['inputChange'],
  setup(props, { emit }) {
    // 文本内容
    const font = ref('')
    const searchInput = () => {
      emit('inputChange', font.value)
    }
    const clearFont = () => {
      font.value = ''
    }

    return { font, searchInput, clearFont }
  },
})


import { defineComponent } from 'vue'
import { getRoleList } from '@/service/main/main'

export default defineComponent({
  async setup() {
    // 获取vuex

    // 获取用户选择数据
    const roleList = [
      {
        key: -1,
        value: '所有角色',
      },
      {
        key: 0,
        value: '超级管理员',
      },
      {
        key: 1,
        value: '管理员',
      },
      {
        key: 2,
        value: '主播',
      },
      {
        key: 3,
        value: '运营者',
      },
    ]
    // 权限索引
    let selectRoleIndex = 0
    const role = roleList[selectRoleIndex].key
    let memberList: any[] = []
    let showMemberList: any[] = []

    // 获取用户列表
    let getRoleListRes = await getRoleList('', role, 0, 30)
    memberList = getRoleListRes
    // console.log(memberList, 'memberList')
    showMemberList = memberList

    const inputChange = (value: any) => {
      showMemberList = memberList.filter((item) => {
        return (
          item.nickname.indexOf(value) !== -1 ||
          item.username.indexOf(value) !== -1
        )
      })
    }
    const roleCompute = (event: any) => {
      // console.log(event)
      let roleName = ''
      event.forEach((element: any) => {
        if (element == 0) {
          roleName += '超级管理员 '
        } else if (element == 1) {
          roleName += '管理员 '
        } else if (element == 2) {
          roleName += '主播 '
        } else if (element == 3) {
          roleName += '运营者 '
        }
      })
      return roleName
    }
    return {
      selectRoleIndex,
      memberList,
      showMemberList,
      inputChange,
      roleCompute,
    }
  },
})

import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b53c8dcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "search-wrap" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        ref: "search",
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.font) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.searchInput && _ctx.searchInput(...args)))
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.font]
      ]),
      _withDirectives(_createElementVNode("i", {
        class: "clear-icon el-icon-error",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearFont && _ctx.clearFont(...args)))
      }, null, 512), [
        [_vShow, _ctx.font]
      ]),
      _createElementVNode("i", {
        class: "el-icon-search search-icon",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.searchInput && _ctx.searchInput(...args)))
      })
    ])
  ]))
}

import { defineComponent, ref, reactive, onMounted } from 'vue'
import { requestRemoveRole } from '@/service/main/main'
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
import { delFormRules } from '../config/roles-config'
export default defineComponent({
  setup() {
    let dialogVisible = ref(false)
    let delForm = ref()
    onMounted(() => delForm)
    let form = reactive({
      wxForm: '',
    })
    const changeVisibility = (value: boolean) => {
      dialogVisible.value = value
    }
    const open = () => {
      ElMessageBox.confirm('此操作将永久删除该成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          requestRemoveRole(form.wxForm, 2)
          dialogVisible.value = false
          ElMessage({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    }
    const removeRole = () => {
      delForm.value.validate(async (valid: any) => {
        if (valid) {
          open()
        }
      })
    }

    return {
      dialogVisible,
      changeVisibility,
      form,
      removeRole,
      delForm,
      delFormRules,
    }
  },
})


import { defineComponent, ref } from 'vue'
import MainSearch from './main-search.vue'

export default defineComponent({
  components: { MainSearch },
  props: {
    title: String,
    placeholder: String,
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const inputChange = (item: any) => {
      // console.log(item)

      emit('inputChange', item)
    }
    return { inputChange }
  },
})

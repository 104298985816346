import { Ref } from '@vue/runtime-dom'
import { HYRequestProduct, HyRequestWxLive, HyRequest } from '../index'
import { LiveInfoResult, getRoleListRes } from './type'

// 枚举类型
enum MainApi {
  GetRoleList = '/GetRoleList',
  liveInfo = '/LiveInfo',
  getRoleList = '/GetRoleList',
  GetWxRoleList = '/WxLive/GetWxRoleList',
  getAddRole = '/WxLive/AddRole',
  removeRole = '/WxLive/RemoveRole',
  shopList = '/WxLive/GoodsGetStartList',
  shopDelete = '/WxLive/GoodsDelete',
  modifyPrice = '/WxLive/GoodSupDate',
  goWxProducts = '/Product/GetWxProducts',
  withdraw = '/WxLive',
  RoomCreate = '/WxLive/RoomCreate',
  GoodsAdd = '/WxLive/GoodsAdds',
  RemoveRoom = '/WxLive/RemoveRoom',
  GetRoomProductList = '/WxLive/GetRoomProductList',
  AddGoods = '/WxLive/AddGoods',
  GetRoomProductShop = '/WxLive/GetRoomProduct',
  RemoveInRoom = '/WxLive/RemoveInRoom',
  OnSale = '/WxLive/OnSale',
  UploadMultimedia = '/WxLive/UploadMultimedia',
  UpdateGoodsStatus = '/WxLive/UpdateGoodsStatus',
}
type SN = number | string
export function getLiveInfo(start: any, limit: any) {
  return HyRequestWxLive.post<LiveInfoResult>({
    url: MainApi.liveInfo + '?start=' + start + '&limit=' + limit,
  })
}
// 获取成员列表
export function getRoleList(
  keyword: any,
  role: any,
  offset: any,
  limit: any,
): any {
  return HyRequestWxLive.get({
    url:
      MainApi.getRoleList +
      '?keyword=' +
      keyword +
      '&=' +
      role +
      '&offset=' +
      offset +
      '&limit=' +
      limit,
  })
}

// 添加成员
export function getAddRole(
  username: string | Ref<string>,
  role: any,
  wxname: any,
): any {
  return HyRequest.post({
    url: MainApi.getAddRole,
    params: {
      username,
      role,
      wxname,
    },
  })
}
// 删除成员
export function requestRemoveRole(
  username: string | Ref<string>,
  role: string | number,
): any {
  return HyRequest.post({
    url: MainApi.removeRole + '?username=' + username + '&role=' + role,
  })
}
// 获取入库和未入库商品列表
export function goShopList(
  name: string | number,
  pageIndex: string | number,
  pageSize: string | number,
  start: string | number,
): any {
  return HyRequest.post({
    url: MainApi.shopList,
    params: {
      name,
      pageIndex,
      pageSize,
      start,
    },
  })
}
// 删除商品
export function goShopDelete(goodsId: string | number): any {
  return HyRequest.post({
    url: MainApi.shopDelete + '?goodsId=' + goodsId,
  })
}
// 添加商品
export function addShopGood(ProductIds: any, type: number | string): any {
  return HyRequest.post({
    url: MainApi.GoodsAdd,
    data: {
      ProductIds,
      type,
    },
  })
}

// 修改商品价格
export function modifyPrice(
  priceType: string | number,
  price: string | number,
  price2: string | number,
  goodsId: string | number,
): any {
  return HyRequest.post({
    url:
      MainApi.modifyPrice +
      '?priceType=' +
      priceType +
      '&price=' +
      price +
      '&price2=' +
      price2 +
      '&goodsId=' +
      goodsId,
  })
}

// 获取商品总列表
export function goWxProducts(
  pageIndex: string | number,
  pageSize: string | number,
): any {
  return HyRequest.get({
    url:
      MainApi.goWxProducts +
      '?pageIndex=' +
      pageIndex +
      '&pageSize=' +
      pageSize,
  })
}
// 撤回商品审核
export function goWithdraw(
  goodsId: string | number,
  auditId: string | number,
): any {
  return HyRequest.post({
    url: MainApi.withdraw + '?goodsId=' + goodsId + '&auditId=' + auditId,
  })
}
// 创建直播间
export function RoomCreate(create: any): any {
  return HyRequest.post({
    url: MainApi.RoomCreate,
    data: create,
  })
}
// 删除直播间
export function DeleteRoom(id: any): any {
  return HyRequest.post({
    url: MainApi.RemoveRoom + '?id=' + id,
  })
}

//直播间导入商品列表
export function GetRoomProductList(
  pageIndex: SN,
  pageSize: SN,
  roomid: any,
  name: SN,
): any {
  return HyRequest.get({
    url: MainApi.GetRoomProductList,
    params: {
      roomid,
      pageSize,
      pageIndex,
      name,
    },
  })
}
//直播间导入添加商品
export function AddGoods(GoodsIds: SN[], roomid: SN | undefined): any {
  return HyRequest.post({
    url: MainApi.AddGoods,
    data: {
      Ids: GoodsIds,
      id: roomid,
    },
  })
}

//直播间展示商品
export function GetRoomProductShop(
  roomId: any,
  name: SN,
  pageSize: SN,
  pageIndex: SN,
): any {
  return HyRequest.get({
    url: MainApi.GetRoomProductShop,
    params: {
      roomId,
      name,
      pageSize,
      pageIndex,
    },
  })
}
// 删除直播间展示商品
export function RemoveInRoom(roomId: any, goodsId: SN): any {
  return HyRequest.post({
    url: MainApi.RemoveInRoom,
    params: {
      roomId,
      goodsId,
    },
  })
}
// 直播间上下架商品
export function OnSale(roomId: any, goodsId: SN, onSale: SN): any {
  return HyRequest.post({
    url: MainApi.OnSale,
    params: {
      roomId,
      goodsId,
      onSale,
    },
  })
}
// 直播上传图片
export function UploadMultimedia(data: any): any {
  return HyRequest.post({
    url: MainApi.UploadMultimedia,
    data: data,
  })
}
// 获取微信主播成员列表
export function GetWxRoleList(): any {
  return HyRequest.get({
    url: MainApi.GetWxRoleList,
  })
}
// 更新商品状态
export function UpdateGoodsStatus(): any {
  return HyRequest.post({
    url: MainApi.UpdateGoodsStatus,
  })
}


import { defineComponent, onMounted, reactive, ref } from 'vue'
import { getAddRole } from '@/service/main/main'
import { ElMessage } from 'element-plus'
import { rules } from '../config/roles-config'
// import { useStore } from 'vuex'
export default defineComponent({
  setup() {
    const wxForm = ref()
    onMounted(() => wxForm)
    // 输入微信号input
    let wxForms = reactive({
      wxFormData: '',
      wxFormName: '',
    })

    // 显示隐藏
    let dialogVisible = ref(false)
    const changeVisibility = (value: boolean) => {
      dialogVisible.value = value
    }

    const step = ref(1)
    const roleList = [
      {
        id: 1,
        name: '主播',
        desc: '可创建本人为主播的直播间并进行直播',
      },
    ]

    // 请求添加用户
    let getAddRoleRes: any = null
    let userCodeUrl = ''
    const AddRole1 = () => {
      const reqRole = async () => {
        //验证通过
        wxForm.value.validate(async (valid: any) => {
          if (valid) {
            getAddRoleRes = await getAddRole(
              wxForms.wxFormData,
              2,
              wxForms.wxFormName,
            )
            if (getAddRoleRes?.errcode == 500) {
              ElMessage.warning({
                message: '请先完成实名认证',
                type: 'warning',
              })
              userCodeUrl = getAddRoleRes.codeurl
              step.value = 2
              // console.log(userCodeUrl)
            }
          }
        })
      }
      reqRole()
    }
    const AddRole2 = () => {
      const reqRole = async () => {
        getAddRoleRes = await getAddRole(
          wxForms.wxFormData,
          2,
          wxForms.wxFormName,
        )
        changeVisibility(false)
        // if (getAddRoleRes?.errcode == 400002) {
        //   ElMessage.warning({
        //     message: '请先完成实名认证',
        //     type: 'warning',
        //   })
        //   userCodeUrl = getAddRoleRes.codeurl
        //   console.log(userCodeUrl)
        // } else if (getAddRoleRes?.errcode == 400001) {
        //   ElMessage.warning({
        //     message: '用户名无效',
        //     type: 'warning',
        //   })
        //   return
        // } else if (getAddRoleRes?.errcode == 2000) {
        //   ElMessage.warning({
        //     message: '该主播已被添加',
        //     type: 'warning',
        //   })
        //   return
        // } else if (getAddRoleRes?.errcode == 0) {
        //   ElMessage.success({
        //     message: '添加成功',
        //     type: 'success',
        //   })

        // }
      }
      reqRole()
    }

    const selectRole = ref('')

    return {
      wxForm,
      dialogVisible,
      changeVisibility,
      rules, // 验证规则
      step,
      roleList,
      selectRole,
      wxForms,
      AddRole1,
      AddRole2,
      getAddRoleRes,
      userCodeUrl,
    }
  },
})

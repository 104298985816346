import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef3e2d04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member_list" }
const _hoisted_2 = { class: "user_item_box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user_item_inner" }
const _hoisted_5 = { class: "user_info" }
const _hoisted_6 = { class: "user_remark" }
const _hoisted_7 = { class: "user_card_tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.showMemberList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "user_item",
        key: item.nickname
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "user_item_img",
            src: item.headingimg,
            alt: ""
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.nickname), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(item.username), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.roleCompute(item.roleList)), 1)
          ])
        ])
      ]))
    }), 128))
  ]))
}
const rules: any = {
  wxFormData: [
    {
      required: true,
      message: '微信号不能为空',
      trigger: 'blur',
    },
  ],
  wxFormName: [
    {
      required: true,
      message: '请输入微信昵称',
      trigger: 'blur',
    },
  ],
}

const delFormRules: any = {
  wxForm: [
    {
      required: true,
      message: '微信号不能为空',
      trigger: 'blur',
    },
  ],
}


export { rules, delFormRules }


import { defineComponent, onMounted, ref } from 'vue'
import { MainHeader } from '@/components/main-header/index'

import MemberCard from './cpns/member-card.vue'
import AddMember from './cpns/add-member.vue'
import RemoveMember from './cpns/remove-member.vue'

export default defineComponent({
  components: { MainHeader, AddMember, MemberCard, RemoveMember },
  async setup() {
    // 拿到ref element
    const addMemberDialog = ref()
    const removeDialog = ref()
    // 生命周期钩子
    onMounted(() => {
      // console.log(addMemberDialog.value)
      return { addMemberDialog, removeDialog }
    })
    // 弹出添加
    const showAddMemberDialog = () => {
      // console.log(addMemberDialog.value.dialogVisible)
      addMemberDialog.value.dialogVisible = true
    }

    // 弹出删除
    const remevoRoles = () => {
      removeDialog.value.dialogVisible = true
    }

    const inputChange = (e: any) => {
      console.log(e)
    }
    return {
      showAddMemberDialog,
      inputChange,
      addMemberDialog,
      removeDialog,
      remevoRoles,
    }
  },
})
